import React from 'react'
import { Modal } from 'antd'
import LoginComponent from './login.component'

const loginModal = ({ visible, onCancel }) => (
  <Modal
    visible={visible}
    footer={null}
    onCancel={onCancel}
    title="Sign in"
  >
    <LoginComponent inModal />
  </Modal>
)

export default loginModal
