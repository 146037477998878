/* eslint-disable object-curly-newline */
import React, { Component } from 'react'
import { message } from 'antd'
import { Link } from 'react-router-dom'


class Signup extends Component {
  state = {
    email: '',
    password: '',
    passwordRepeat: '',
  }

  handleEmailChange = (e) => {
    this.setState({
      email: e.target.value,
    })
  }

  handlePasswordChange = (e) => {
    this.setState({
      password: e.target.value,
    })
  }

  handlePasswordRepeatChange = (e) => {
    this.setState({
      passwordRepeat: e.target.value,
    })
  }

  handleSignupClick = async () => {
    const { password, passwordRepeat, email } = this.state
    if (password !== passwordRepeat) {
      return message.error('Passwords do not match.')
    }
    const response = await (await fetch('/api/user/signup', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        email,
        password,
      }),
    })).json()
    if (response.error) {
      return message.error(response.error_description)
    }
    window.location.href = '/app/projects'
    return true
  }

  render() {
    const { email, password, passwordRepeat } = this.state
    return (
      <div>
        <div className="row justify-content-center no-gutters">
          <div className="col-xl-9 col-sm-10">
            <div className="px-15">
              <input
                type="email"
                name="email"
                value={email}
                onChange={this.handleEmailChange}
                placeholder="Your email"
                required="required"
                className="input mb-30 w-full border-gray focus-action-1 color-heading placeholder-heading text-center text-md-left"
              />
              <input
                type="password"
                name="password"
                value={password}
                onChange={this.handlePasswordChange}
                placeholder="Your password"
                required="required"
                className="input mb-30 w-full border-gray focus-action-1 color-heading placeholder-heading text-center text-md-left"
              />
              <input
                type="password"
                name="password"
                value={passwordRepeat}
                onChange={this.handlePasswordRepeatChange}
                placeholder="Repeat password"
                required="required"
                className="input mb-30 w-full border-gray focus-action-1 color-heading placeholder-heading text-center text-md-left"
              />

              <button type="button" onClick={this.handleSignupClick} className="btn mt-10 w-full action-1">Create an Account</button>

              <div className="f-14 color-heading text-center mt-20 separate">
                <span className="d-inline-block px-10 bg-light relative">or</span>
              </div>

              <Link to="/login" className="btn mt-20 w-full action-twitter">Sign In</Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Signup
