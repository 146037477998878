const tinycolor = require('tinycolor2')

const categorizeVariable = (value) => {
  if (String(value) === 'true' || String(value) === 'false') {
    return 'boolean'
  }
  if (!isNaN(value)) {
    return 'size'
  }
  const trimmedValue = String(value).trim()
  const color = tinycolor(value)
  if (color._ok) {
    return 'color'
  }
  if ((trimmedValue.indexOf('$') === 0) && (trimmedValue.split(' ').length === 1)) {
    return 'variable'
  }
  const splitUnit = value.split(/em|rem|pt|px|%|vw|vh/)
  if (splitUnit.length === 2 && !isNaN(splitUnit[0]) && !splitUnit[1]) {
    return 'size'
  }
  if (value.indexOf('-apple-system') !== -1 || value.indexOf('SFMono-Regular') !== -1) {
    return 'font'
  }
  return 'string'
}

module.exports = categorizeVariable
