import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Helmet from 'react-helmet'

export default (
  <Switch>
    <Route
      path="/"
      render={() => (
        <Helmet>
          <title>Free Bootstrap Builder, Build Themes & Templates - Bootstrap.Build</title>
          <meta name="description" content="Create free Bootstrap themes in the builder tool and export SASS or full Bootstrap CSS build. Start from scratch or reuse hundreds of ready-to-go Bootstrap templates." />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Free Bootstrap Builder - Build Themes & Templates" />
          <meta property="og:description" content="Create free Bootstrap themes in the builder tool and export SASS or full Bootstrap CSS build. Start from scratch or reuse hundreds of ready-to-go Bootstrap templates." />
          <meta property="og:url" content="https://bootstrap.build/" />
          <meta property="og:site_name" content="Bootstrap.Build" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:description" content="Create free Bootstrap themes in the builder tool and export SASS or full Bootstrap CSS build. Start from scratch or reuse hundreds of ready-to-go Bootstrap templates." />
          <meta name="twitter:title" content="Free Bootstrap Builder - Build Themes & Templates" />
          <meta name="twitter:site" content="@bootstrap_build" />
          <meta name="twitter:image" content="./images/social-media.jpg" />
          <meta property="og:image" content="./images/social-media.jpg" />
          <meta property="og:image:secure_url" content="./images/social-media.jpg" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
      )}
      exact
    />
    <Route
      path="/app"
      render={() => (
        <Helmet>
          <title>Builder – Bootstrap Build</title>
          <meta name="viewport" content="width=700, initial-scale=0.1" />
        </Helmet>
      )}
      exact
    />
    <Route
      path="/app/project/:projectId"
      render={() => (
        <Helmet>
          <meta name="viewport" content="width=1000, initial-scale=0.1" />
        </Helmet>
      )}
      exact
    />
    <Route
      path="/themes"
      render={() => (
        <Helmet>
          <title>Free Bootstrap Themes - Bootstrap.Build</title>
          <meta name="description" content="Create and download free Bootstrap themes from our builder, export SASS or full Bootstrap CSS build. Start from scratch or reuse ready-to-go Bootstrap theme." />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Free Bootstrap Themes - Bootstrap.Build" />
          <meta property="og:description" content="Create and download free Bootstrap themes from our builder, export SASS or full Bootstrap CSS build. Start from scratch or reuse ready-to-go Bootstrap theme." />
          <meta property="og:url" content="https://bootstrap.build/" />
          <meta property="og:site_name" content="Bootstrap.Build" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:description" content="Create and download free Bootstrap themes from our builder, export SASS or full Bootstrap CSS build. Start from scratch or reuse ready-to-go Bootstrap theme." />
          <meta name="twitter:title" content="Free Bootstrap Themes - Bootstrap.Build" />
          <meta name="twitter:site" content="@bootstrap_build" />
          <meta name="twitter:image" content="./images/social-media.jpg" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
      )}
      exact
    />
    <Route
      path="/templates"
      render={() => (
        <Helmet>
          <title>Free Bootstrap Templates - Bootstrap.Build</title>
          <meta name="description" content="Download free Bootstrap templates from our collection, export SASS or full Bootstrap CSS build. Start from scratch or reuse ready-to-go Bootstrap template." />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Free Bootstrap Templates - Bootstrap.Build" />
          <meta property="og:description" content="Download free Bootstrap templates from our collection, export SASS or full Bootstrap CSS build. Start from scratch or reuse ready-to-go Bootstrap template." />
          <meta property="og:url" content="https://bootstrap.build/" />
          <meta property="og:site_name" content="Bootstrap.Build" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:description" content="Download free Bootstrap templates from our collection, export SASS or full Bootstrap CSS build. Start from scratch or reuse ready-to-go Bootstrap template." />
          <meta name="twitter:title" content="Free Bootstrap Templates - Bootstrap.Build" />
          <meta name="twitter:site" content="@bootstrap_build" />
          <meta name="twitter:image" content="./images/social-media.jpg" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
      )}
      exact
    />
    <Route
      path="/login"
      render={() => (
        <Helmet>
          <title>Log in – Bootstrap Build</title>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
      )}
      exact
    />
    <Route
      path="/signup"
      render={() => (
        <Helmet>
          <title>Sign up – Bootstrap Build</title>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
      )}
      exact
    />
    <Route
      path="/terms"
      render={() => (
        <Helmet>
          <title>Terms – Bootstrap Build</title>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="robots" content="noindex" />
        </Helmet>
      )}
      exact
    />
    <Route
      path="/privacy"
      render={() => (
        <Helmet>
          <title>Privacy – Bootstrap Build</title>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="robots" content="noindex" />
        </Helmet>
      )}
      exact
    />
    <Route
      path="/license"
      render={() => (
        <Helmet>
          <title>License – Bootstrap Build</title>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="robots" content="noindex" />
        </Helmet>
      )}
      exact
    />
    <Route
      path="/app/projects"
      render={() => (
        <Helmet>
          <title>My Projects – Bootstrap Build</title>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <script src="https://js.stripe.com/v3/" />
        </Helmet>
      )}
      exact
    />
  </Switch>
)
