const resolveVariables = (overwrites, referenceVars, cyclesRan) => {
  if (cyclesRan === Object.keys(overwrites).length) {
    return overwrites
  }
  const out = {}
  Object.keys(overwrites).forEach((key) => {
    const matches = overwrites[key].match(/\$[0a-zA-Z1-9\-_]*/gmi)
    if (matches) {
      // resolves variables like $blue
      matches.forEach((match) => {
        if (overwrites[match]) {
          out[match] = overwrites[match]
        } else if (referenceVars[match]) {
          out[match] = referenceVars[match]
        }
      })
    }
    out[key] = overwrites[key].trim()
  })
  return resolveVariables(out, referenceVars, Object.keys(overwrites).length)
}

export default resolveVariables
