import {
  parseGoogleFontsFromVariables,
  parseFontWeightsFromVariables,
  getGoogleFontsQueries,
  categorizeVariable,
} from '../utils'

import {
  BUILDER_SET_VARIABLES,
  BUIDER_SET_INITIAL_VARIABLES,
  BUILDER_SET_STATE,
  BUILDER_SET_COMPILED_CSS,
  BUILDER_SET_COMPILE_LOADING,
  BUILDER_SET_ACTIVE_VAR_SECTION,
  BUILDER_CHANGE_VARIABLE,
  BUILDER_APPLY_OVERWRITES,
  BUILDER_SET_CUSTOM_SCSS,
  BUILDER_SET_TOOLBAR_MODE,
  BUILDER_FLUSH,
  SET_PREVIEW_CONFIG,
  ADD_LOG_ENTRY,
  BUILDER_SET_FONTS,
  GET_AUTOSUGGEST_VALUES,
} from './builder.actions'

const defaultState = {
  variables: [],
  initialVariables: [],
  overwrites: {},
  fonts: [],
  customSass: '',
  compiledCss: '',
  compiling: false,
  previewMode: 'uikit',
  previewSection: 'Buttons',
  variableMode: 'by_section',
  variableSection: 'Color system',
  logs: [],
  suggestData: [],
}

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case BUILDER_SET_VARIABLES:
      return {
        ...state,
        variables: action.payload,
      }
    case BUILDER_SET_STATE: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case BUIDER_SET_INITIAL_VARIABLES: {
      return {
        ...state,
        initialVariables: action.payload,
      }
    }
    case BUILDER_SET_COMPILED_CSS: {
      return {
        ...state,
        compiledCss: action.payload,
      }
    }
    case BUILDER_SET_COMPILE_LOADING: {
      return {
        ...state,
        compiling: action.payload,
      }
    }
    case BUILDER_SET_ACTIVE_VAR_SECTION: {
      return {
        ...state,
        variableSection: action.payload,
      }
    }
    case BUILDER_SET_FONTS: {
      return {
        ...state,
        fonts: action.payload,
      }
    }
    case BUILDER_APPLY_OVERWRITES: {
      // sets overwrites to overwrites prop in state + updates variables object (and left menu)
      const newOverwrites = action.payload
      const { variables, overwrites } = state
      return {
        ...state,
        overwrites: {
          ...overwrites,
          ...newOverwrites,
        },
        variables: variables.map(variable => (newOverwrites[variable.name] ? {
          ...variable,
          value: newOverwrites[variable.name],
          type: categorizeVariable(newOverwrites[variable.name]),
        } : variable)),
      }
    }
    case BUILDER_SET_CUSTOM_SCSS: {
      const { payload } = action
      return {
        ...state,
        customSass: payload,
      }
    }
    case BUILDER_SET_TOOLBAR_MODE: {
      const { payload } = action
      return {
        ...state,
        toolbarMode: payload,
      }
    }
    case SET_PREVIEW_CONFIG: {
      const { payload } = action
      return {
        ...state,
        previewMode: payload.previewMode,
        previewSection: payload.previewSection,
      }
    }
    case BUILDER_CHANGE_VARIABLE: {
      const { payload } = action
      const { variables, overwrites } = state
      const { [payload.name]: oldValue, ...withoutOldVariable } = overwrites
      const settingDefault = (payload.value === payload.defaultValue)
      const newOverwrites = settingDefault ? withoutOldVariable : {
        ...overwrites,
        [payload.name]: payload.value,
      }
      const fontFamilies = parseGoogleFontsFromVariables(overwrites)
      const fontWeights = parseFontWeightsFromVariables(overwrites)
      const fonts = getGoogleFontsQueries(fontFamilies, fontWeights)
      return {
        ...state,
        variables: variables.map(variable => (variable.name === payload.name ? payload : variable)),
        overwrites: newOverwrites,
        fonts: fonts && (fonts.length > 0) ? fonts : state.fonts,
      }
    }
    case BUILDER_FLUSH: {
      return {
        ...defaultState,
      }
    }
    case ADD_LOG_ENTRY: {
      const { payload } = action
      return {
        ...state,
        logs: [...state.logs, payload],
      }
    }
    case GET_AUTOSUGGEST_VALUES: {
      const { variables } = state
      const { payload } = action
      const suggestData = variables.map(({ name }) => name).filter(variable => variable.indexOf(payload) !== -1)
      return {
        ...state,
        suggestData,
      }
    }
    default:
  }
  return state
}
