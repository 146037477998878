import React from 'react'
import { Link } from 'react-router-dom'
import useWindowWidth from '../hooks/useWindowWidth'

const Navigation = () => {
  const width = useWindowWidth()

  return (
    <>
      <nav className={`header_menu_1 pt-40 pb-10 ${width < 1199 ? 'd-none' : ''}`}>
        <div className="container px-xl-0">
          <div className="row justify-content-center align-items-center f-18 medium">
            <div className="col-lg-3 logo">
              <Link to="/" className="link color-heading mx-15">Bootstrap Build</Link>
            </div>
            <div className="col-lg-6 text-center">
              <Link to="/themes" className="link color-heading mx-15">Free Themes</Link>
              <Link to="/templates" className="link color-heading mx-15">Free Templates</Link>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 d-flex justify-content-end align-items-center">
              <Link to="/login" className="link mr-20 color-heading f-16">Log In</Link>
              <Link to="/signup" className="btn sm action-1 f-16">Sign Up</Link>
            </div>
          </div>
        </div>
      </nav>
  </>
  )
}

export default Navigation
