import React from 'react'
import { Modal } from 'antd'

const confirmEmailModal = ({ visible, email }) => (
  <div>
    <Modal
      visible={visible}
      title="Almost there!"
      footer={null}
      closable={false}
    >
      <div className="h-overflow">
        <div className="pull-left" style={{ width: 140 }}>
          <img src="/images/email-subscription.svg" alt="email" width={140} />
        </div>
        <div style={{ marginLeft: 160, marginTop: 20 }}>
          Please verify your email address. We have sent a confirmation link to
          {' '}
          <b>{email}</b>
          .
        </div>
      </div>
    </Modal>
  </div>
)

export default confirmEmailModal
