import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

import BodyClassName from 'react-body-classname'

import Header from '../pages/components/navigation'
import Footer from '../pages/components/footer'

import LoginForm from './login.component'
import Signup from './signup.component'

const Login = () => {
  const location = useLocation()
  const [signin, setSignin] = location.pathname === '/login' ? useState(true) : useState(false)
  const [signup, setSingup] = location.pathname === '/signup' ? useState(true) : useState(false)

  function handleSignupTab() {
    setSingup(true)
    setSignin(false)
  }

  function handleSigninTab() {
    setSignin(true)
    setSingup(false)
  }

  return (
    <BodyClassName className="login-page">
      <div>
        <Header />
        <section className="pt-100 pb-100 bg-dark color-filter-dark-5 form_4">
          <div className="container px-xl-0">
            <div className="row align-items-center">
              <div className="col-xl-1" />
              <div className="col-xl-4 col-lg-5 col-md-8 color-white">
                <h2 className="mb-30 small">Create and save your project online</h2>
                <div className="text-adaptive">Bootstrap.build is a web app to create custom Bootstrap themes and export them to a SASS variables file or a brand new project. Enjoy inline code editor, Sketch-like color picker, +/- buttons for unit variables, SASS variables typeahead and Google fonts integration. Open Source!</div>
              </div>
              <div className="col-lg-1" />
              <div className="mt-50 mt-lg-0 col-xl-5 col-lg-6 col-md-8">
                <div className="bg-light radius10">
                  <div className="form_4_menu text-center">
                    <span
                      onClick={handleSignupTab}
                      className={signup ? 'link active relative f-14 semibold text-uppercase sp-20 color-heading lh-68' : 'link relative f-14 semibold text-uppercase sp-20 color-heading lh-68'}
                    >
Sign Up
                    </span>
                    <span
                      onClick={handleSigninTab}
                      className={signin ? 'link active relative relative f-14 semibold text-uppercase sp-20 color-heading lh-68' : 'link relative relative f-14 semibold text-uppercase sp-20 color-heading lh-68'}
                    >
Login
                    </span>
                  </div>
                  <div className="mt-65 pb-80">
                    {signin
                      ? <LoginForm />
                      : <Signup />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </BodyClassName>
  )
}

export default Login
