import React, { Component } from 'react'
import { message } from 'antd'

import BodyClassName from 'react-body-classname'

import Header from '../pages/components/navigation'
import Footer from '../pages/components/footer'

export default class Login extends Component {
  state = {
    email: '',
    password: '',
  }

  handleForgot = async () => {
    const { email } = this.state
    const loginReq = await fetch('/api/user/forgot', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        email,
      }),
    })
    const response = await loginReq.json()
    if (!loginReq.ok) {
      return message.error(response.error_description)
    }
    return message.success(response.message)
  }

  handleReset = async () => {
    const { match, history } = this.props
    const { code } = match.params
    const { password } = this.state
    const loginReq = await fetch('/api/user/forgot', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        code,
        password,
      }),
    })
    const response = await loginReq.json()
    if (!loginReq.ok) {
      return message.error(response.error_description)
    }
    setTimeout(() => {
      history.push('/login')
    }, 3000)
    message.success(response.message)
  }

  handleEmailChange = (e) => {
    this.setState({
      email: e.target.value,
    })
  }

  handlePasswordChange = (e) => {
    this.setState({
      password: e.target.value,
    })
  }

  render() {
    const { match } = this.props
    const { code } = match.params
    const { email, password } = this.state

    return (
      <BodyClassName className="login-page">
        <div>
          <Header />
          <section className="pt-100 pb-100 bg-dark color-filter-dark-5 form_4">
            <div className="container px-xl-0">
              <div className="row align-items-center">
                <div className="col-xl-1" />
                <div className="col-xl-4 col-lg-5 col-md-8 color-white">
                  <h2 className="mb-30 small">Create and save your project online</h2>
                  <div className="text-adaptive">Bootstrap.build is a web app to create custom Bootstrap themes and export them to a SASS variables file or a brand new project. Enjoy inline code editor, Sketch-like color picker, +/- buttons for unit variables, SASS variables typeahead and Google fonts integration. Open Source!</div>
                </div>
                <div className="col-lg-1" />
                <div className="mt-50 mt-lg-0 col-xl-5 col-lg-6 col-md-8">
                  <div className="bg-light radius10">
                    <div className="form_4_menu forgot-password text-center">
                      <span className="link active relative f-14 semibold text-uppercase sp-20 color-heading lh-68">
Reset Password
                      </span>
                    </div>
                    <div className="mt-65 pb-80">
                      <div>
                        <div className="row justify-content-center no-gutters">
                          <div className="col-xl-9 col-sm-10">
                            <div className="px-15">
                              {code ? (
              <>
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={password}
                  onChange={this.handlePasswordChange}
                  required="required"
                  className="input mb-30 w-full border-gray focus-action-1 color-heading placeholder-heading text-center text-md-left"
                />

                <button type="button" onClick={this.handleReset} className="btn mt-10 w-full action-twitter">Confirm</button>
              </>
                              )
                                : <>
                                  <input
                                    type="email"
                                    name="email"
                                    value={email}
                                    onChange={this.handleEmailChange}
                                    placeholder="Your email"
                                    required="required"
                                    className="input mb-30 w-full border-gray focus-action-1 color-heading placeholder-heading text-center text-md-left"
                                  />

                                  <button type="button" onClick={this.handleForgot} className="btn mt-10 w-full action-twitter">Reset Password</button>
              </>
            }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </BodyClassName>
    )
  }
}
