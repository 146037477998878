const parseSassFile = (importedVariablesString) => {
  const lines = importedVariablesString.split('\n').filter(line => line.trim().indexOf('$') === 0)
  const importVars = {}
  lines.forEach(line => {
    let splittedLine = line.replace('!default', '').replace(';', '').split(':')
    if (splittedLine.length === 2) {
      importVars[splittedLine[0].trim()] = splittedLine[1].trim()
    }
  })
  return importVars
}

export default parseSassFile