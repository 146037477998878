import {
  GET_USER,
  GET_USER_RESOLVE,
  SHOW_OPT_IN_MODAL,
  HIDE_OPT_IN_MODAL,
  SHOW_LOGIN_MODAL,
  HIDE_LOGIN_MODAL,
} from './auth.actions'

export default (state = {
  loading: true,
}, action = {}) => {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        loading: true,
      }
    case GET_USER_RESOLVE:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case SHOW_OPT_IN_MODAL:
      return {
        ...state,
        showOptinModal: true,
      }
    case HIDE_OPT_IN_MODAL:
      return {
        ...state,
        showOptinModal: false,
      }
    case SHOW_LOGIN_MODAL:
      return {
        ...state,
        showLoginModal: true,
      }
    case HIDE_LOGIN_MODAL:
      return {
        ...state,
        showLoginModal: false,
      }
    default:
  }
  return state
}
