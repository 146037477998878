import { notification } from 'antd'
import {
  postConfig,
} from '../utils'

export const BUILDER_SET_VARIABLES = 'BUILDER_SET_VARIABLES'
export const BUILDER_SET_STATE = 'BUILDER_SET_STATE'
export const BUILDER_COMPILE = 'BUILDER_COMPILE'
export const BUILDER_SET_COMPILE_LOADING = 'BUILDER_SET_COMPILE_LOADING'
export const BUILDER_SET_COMPILED_CSS = 'BUILDER_SET_COMPILED_CSS'
export const BUILDER_SET_ACTIVE_VAR_SECTION = 'BUILDER_SET_ACTIVE_VAR_SECTION'
export const BUILDER_CHANGE_VARIABLE = 'BUILDER_CHANGE_VARIABLE'
export const BUILDER_APPLY_OVERWRITES = 'BUILDER_APPLY_OVERWRITES'
export const BUILDER_SET_CUSTOM_SCSS = 'BUILDER_SET_CUSTOM_SCSS'
export const BUILDER_SET_TOOLBAR_MODE = 'BUILDER_SET_TOOLBAR_MODE'
export const BUIDER_SET_INITIAL_VARIABLES = 'BUIDER_SET_INITIAL_VARIABLES'
export const SET_PREVIEW_CONFIG = 'SET_PREVIEW_CONFIG'
export const ADD_LOG_ENTRY = 'ADD_LOG_ENTRY'
export const BUILDER_FLUSH = 'BUILDER_FLUSH'
export const BUILDER_SET_FONTS = 'BUILDER_SET_FONTS'
export const GET_AUTOSUGGEST_VALUES = 'GET_AUTOSUGGEST_VALUES'

export const flushBulder = () => ({
  type: BUILDER_FLUSH,
})

export const setVariables = payload => ({
  type: BUILDER_SET_VARIABLES,
  payload,
})

export const setInitialVariables = payload => ({
  type: BUIDER_SET_INITIAL_VARIABLES,
  payload,
})

export const setPreviewConfig = payload => ({
  type: SET_PREVIEW_CONFIG,
  payload,
})

export const setBuilderState = payload => ({
  type: BUILDER_SET_STATE,
  payload,
})

export const setCompiledCss = payload => ({
  type: BUILDER_SET_COMPILED_CSS,
  payload,
})

export const setCompileLoading = payload => ({
  type: BUILDER_SET_COMPILE_LOADING,
  payload,
})

export const setVariableSection = payload => ({
  type: BUILDER_SET_ACTIVE_VAR_SECTION,
  payload,
})

export const changeVariable = payload => ({
  type: BUILDER_CHANGE_VARIABLE,
  payload,
})

export const applyOverwrites = payload => ({
  type: BUILDER_APPLY_OVERWRITES,
  payload,
})

export const setFonts = payload => ({
  type: BUILDER_SET_FONTS,
  payload,
})

export const setCustomScss = payload => ({
  type: BUILDER_SET_CUSTOM_SCSS,
  payload,
})

export const setToolbarMode = payload => ({
  type: BUILDER_SET_TOOLBAR_MODE,
  payload,
})

export const addLogEntry = payload => ({
  type: ADD_LOG_ENTRY,
  payload,
})

export const compile = themeData => async (dispatch, getState) => {
  try {
    dispatch(setCompileLoading(true))
    const { variables, customSass } = themeData
    const { project, user } = getState()
    const { currentProject } = project
    let projectId
    if (currentProject.data && currentProject.data.id && user.data && user.data.email) {
      projectId = currentProject.data.id
    }
    const compileRequest = await fetch('/api/sass/compile', {
      ...postConfig,
      body: JSON.stringify({
        variables,
        customSass,
        projectId,
      }),
    })
    if (compileRequest.ok) {
      const response = await compileRequest.json()
      dispatch(setCompiledCss(response.css))
    } else {
      try {
        const error = await compileRequest.json()
        notification.error({
          message: 'SASS error:',
          description: error && error.stack_trace && error.stack_trace.message,
        })
        dispatch(addLogEntry({
          type: 'error',
          message: error.stack_trace.message,
        }))
      } catch (err) {
        notification.error({
          message: 'SASS compiler error',
        })
      }
    }
  } catch (err) {
    //
  }
  dispatch(setCompileLoading(false))
}

export const getAutoSuggestData = query => ({
  type: GET_AUTOSUGGEST_VALUES,
  payload: query,
})
