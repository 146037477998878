/* eslint-disable object-curly-newline */
import React, { Component } from 'react'
import { message } from 'antd'
import { Link } from 'react-router-dom'

export default class Login extends Component {
  state = {
    email: '',
    password: '',
  }

  handleLogin = async () => {
    const { email, password } = this.state
    const loginReq = await fetch('/api/user/login', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        email,
        password,
      }),
    })
    if (!loginReq.ok) {
      const response = await loginReq.json()
      return message.error(response.error_description)
    }
    window.location.href = '/app/projects'
  }

  handleGoogleLoginClick = () => {
    window.location.href = '/api/user/google'
  }

  handleEmailChange = (e) => {
    this.setState({
      email: e.target.value,
    })
  }

  handlePasswordChange = (e) => {
    this.setState({
      password: e.target.value,
    })
  }

  handleSignupLinkClick = (event) => {
    const { inModal } = this.props
    if (inModal) {
      event.preventDefault()
      window.location.href = '/signup'
    }
  }

  render() {
    const { email, password } = this.state
    return (
      <div>
        <div className="row justify-content-center no-gutters">
          <div className="col-xl-9 col-sm-10">
            <div className="px-15">
              <input
                type="email"
                name="email"
                value={email}
                onChange={this.handleEmailChange}
                placeholder="Your email"
                required="required"
                className="input mb-30 w-full border-gray focus-action-1 color-heading placeholder-heading text-center text-md-left"
              />

              <input
                type="password"
                name="password"
                placeholder="Your password"
                value={password}
                onChange={this.handlePasswordChange}
                required="required"
                className="input mb-30 w-full border-gray focus-action-1 color-heading placeholder-heading text-center text-md-left"
              />

              <button type="button" onClick={this.handleLogin} className="btn mt-10 w-full action-twitter">Sign In</button>

              <div className="f-14 color-heading text-center mt-20 separate">
                <span className="d-inline-block px-10 bg-light relative">or</span>
              </div>

              <Link to="/signup" onClick={this.handleSignupLinkClick} className="btn mt-20 w-full action-1">Create an Account</Link>

              <div className="f-14 color-heading text-center mt-20 separate">
                <span className="d-inline-block px-10 bg-light relative">
                  <Link to="/forgot" onClick={this.handleSignupLinkClick}>Reset Password</Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
