/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => (
  <footer className="pt-45 pb-60 bg-dark color-white text-center text-lg-left footer_13">
    <div className="container px-xl-0">
      <div>
        <div className="mt-35 mb-40 hr h-2 bg-white op-3" />
      </div>
      <div className="row">
        <div className="col-lg-7">
          <div className="row">
            <div className="col-xl-4 col-lg-5">
              <Link to="/" className="link logo color-white">Bootstrap Build</Link>
            </div>
            <div className="mt-15 mb-15 my-lg-0 col-xl-8 col-lg-7">
              <div className="text-adaptive">Send us a <span role="img" aria-labelledby="Heart">❤️</span> on info@ourwebsitedomain</div>
            </div>
          </div>
        </div>
        <div className="col-lg-5 text-lg-right">
          <a href="https://bootstrap.build/articles/" rel="noopener noreferrer" className="link color-white mx-15">Articles</a>
          <Link to="/privacy" className="link color-white mx-15">Privacy Policy</Link>
          <Link to="/terms" className="link color-white mx-15">Terms</Link>
          <Link to="/license" className="link color-white mx-15">License</Link>
          <a href="https://pulsetic.com/" target="_blank" rel="noopener noreferrer" className="link color-white mx-15">Pulsetic</a>
          <span className="d-inline-block socials">
          <a href="https://twitter.com/bootstrap_build" target="_blank" rel="noopener noreferrer" className="link color-white mx-15">
              <i className="fab fa-twitter" />
            </a>
          </span>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
