import {
  GET_PROJECT,
  GOT_PROJECT,
  FLUSH_PROJECT,
  GOT_PROJECTS,
  GET_PROJECTS,
} from './project.actions'

export default (state = {
  currentProject: {
    loading: false,
  },
  myProjects: {
    loading: false,
    data: [],
  },
}, action = {}) => {
  switch (action.type) {
    case GET_PROJECT:
      return {
        ...state,
        currentProject: {
          loading: true,
        },
      }
    case GOT_PROJECT:
      return {
        ...state,
        currentProject: {
          ...action.payload,
          loading: false,
        },
      }
    case FLUSH_PROJECT:
      return {
        ...state,
        currentProject: {
          loading: false,
        },
      }
    case GOT_PROJECTS:
      return {
        ...state,
        myProjects: {
          data: action.payload ? [...action.payload] : [],
          loading: false,
        },
      }
    case GET_PROJECTS:
      return {
        ...state,
        myProjects: {
          loading: true,
        },
      }
    default:
  }
  return state
}
