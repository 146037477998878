import React from 'react'
import { Link } from 'react-router-dom'

import MobileNavigation from './components/mobileNavigation'
import Navigation from './components/navigation'
import Footer from './components/footer'

import './scss/style.scss'

const Landing = () => (
    <>
      <MobileNavigation />

      <header className="pt-120 bg-light header_1">
        <Navigation />

        <div className="container">
          <h1 className="big text-center mt-80">Free Bootstrap Builder</h1>
          <div className="mw-600 mx-auto mt-30 f-22 color-heading text-center text-adaptive">
Create Bootstrap 5 themes and templates. Start from scratch or reuse free featured designs.
          </div>
          <div className="mt-80 text-center buttons">
            <div>
              <Link to="/app" className="btn action-1 xl mt-40">Open the Builder</Link>
            </div>
            <div>
              <p className="mt-15 f-18 medium mb-80">Bootstrap 5</p>
            </div>
          </div>
        </div>
      </header>

      <section className="pt-105 pb-70 bg-dark color-white feature_47">
        <div className="container px-xl-0">
          <h2 className="mb-65 small text-center">Bootstrap Builder Features</h2>
          <div className="row justify-content-center text-center text-md-left">
            <div className="mb-30 col-lg-4 col-md-6 col-sm-8 d-flex align-items-stretch">
              <div className="pt-35 pb-45 pl-40 pr-30 radius10 block">
                <i className="fas fa-code f-32" />
                <div className="mt-20 mb-20 f-14 semibold text-uppercase sp-20 title">SASS compiler</div>
                <div className="text-adaptive">
Use powers of the modern CSS preprocessor to create stunning themes.
                </div>
              </div>
            </div>
            <div className="mb-30 col-lg-4 col-md-6 col-sm-8 d-flex align-items-stretch">
              <div className="pt-35 pb-45 pl-40 pr-30 radius10 block">
                <i className="fas fa-terminal f-32" />
                <div className="mt-20 mb-20 f-14 semibold text-uppercase sp-20 title">Variables lookup</div>
                <div className="text-adaptive">
Customize 500+ Bootstrap variables categorized by UI component category or available via search.
                </div>
              </div>
            </div>
            <div className="mb-30 col-lg-4 col-md-6 col-sm-8 d-flex align-items-stretch">
              <div className="pt-35 pb-45 pl-40 pr-30 radius10 block">
                <i className="fas fa-window-maximize f-32" />
                <div className="mt-20 mb-20 f-14 semibold text-uppercase sp-20 title">Free Bootstrap themes</div>
                <div className="text-adaptive">Browse hundreds of featured themes & templates and load them into the editor for further customization.</div>
              </div>
            </div>
            <div className="mb-30 col-lg-4 col-md-6 col-sm-8 d-flex align-items-stretch">
              <div className="pt-35 pb-45 pl-40 pr-30 radius10 block">
                <i className="fas fa-download f-32" />
                <div className="mt-20 mb-20 f-14 semibold text-uppercase sp-20 title">Bootstrap the right way</div>
                <div className="text-adaptive">
Export a maintanable SASS file with variables and your custom code.
                </div>
              </div>
            </div>
            <div className="mb-30 col-lg-4 col-md-6 col-sm-8 d-flex align-items-stretch">
              <div className="pt-35 pb-45 pl-40 pr-30 radius10 block">
                <i className="fas fa-save f-32" />
                <div className="mt-20 mb-20 f-14 semibold text-uppercase sp-20 title">Save your work in the cloud</div>
                <div className="text-adaptive">
Organize your themes into projects and save them in the cloud for future work and collaboration.
                </div>
              </div>
            </div>
            <div className="mb-30 col-lg-4 col-md-6 col-sm-8 d-flex align-items-stretch">
              <div className="pt-35 pb-45 pl-40 pr-30 radius10 block">
                <i className="fas fa-cloud-upload-alt f-32" />
                <div className="mt-20 mb-20 f-14 semibold text-uppercase sp-20 title">Serve from our CDN</div>
                <div className="text-adaptive">
Make changes to your theme on the fly and leverage our CDN to serve your CSS.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-100 pb-100 bg-light application_12">
        <div className="container px-xl-0">
          <div className="row justify-content-center align-items-center">
            <div className="mb-30 mb-md-0 col-md-5 col-lg-6 d-flex justify-content-start justify-content-md-center">
              <img src="/images/application_12_img_1.png" srcSet="/images/application_12_img_1@2x.png 2x" alt="" className="img-fluid" />
            </div>
            <div className="col-md-7 col-lg-6 no-gutters">
              <div className="pb-15 color-heading f-14 bold text-uppercase sp-20">About</div>
              <h3 className="pb-25">BOOTSTRAP BUILD 2.0</h3>
              <div className="col-lg-10 col-xl-9 pb-40 color-heading f-18 medium text-adaptive">Skip hours of tooling setup to create a custom theme for Bootstrap 5. Our bootstrap builder tool offers many advanced Bootstrap themeing capabilities, such as live preview, variable search, color picker, size adjuster and more.</div>
              <div className="col-lg-10 col-xl-9 pb-40 color-heading f-18 medium text-adaptive">Create a theme for your project by modifing organized variable groups based on official Bootstrap themeing guide. Or browse hundreds of free Bootstrap themes and fork them for further customization.</div>
              <div className="d-flex flex-column flex-sm-row justify-content-start pb-5">
                <div className="mr-0 mr-sm-2 d-flex justify-content-start justify-content-sm-center align-items-center pb-lg-4">
                  <a href="https://www.producthunt.com/posts/bootstrap-build" target="_blank" rel="noopener noreferrer nofollow"><img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=116597&theme=dark&period=daily" alt="Bootstrap.build - A powerful Bootstrap theme builder" width="250px" height="54px" style={{ width: '200px', height: '43px' }} /></a>
                </div>
                <div className="ml-0 ml-sm-2 mt-3 mt-sm-0 d-flex justify-content-start justify-content-sm-center align-items-center pb-lg-4">
                  <a className="radius6" href="https://stackshare.io/posts/top-developer-tools-2018" target="_blank" rel="noopener noreferrer nofollow"><img src="/images/stackshare.png" alt="Top Developer Tools Stackshare" width="250px" height="54px" style={{ width: '200px', height: '43px' }} /></a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <section className="pt-105 pb-100 bg-light text-center pricing_table_7">
        <div className="container px-xl-0">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-10">
              <h2 className="mb-15 small">Cost?</h2>
            </div>
            <div className="col-xl-6 col-lg-7 col-md-8 col-sm-10">
              <div className="mb-50 f-22 color-heading text-adaptive">Bootstrap.build is a free Bootstrap builder.</div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-7 col-md-8 col-sm-10">
              <div className="pt-40 pb-45 radius10 inner">
                <div className="row justify-content-center no-gutters">
                  <div className="col-xl-7 col-lg-8 col-sm-9 col-11">
                    <div className="f-28 title">100% Free</div>
                    <div className="mt-25 f-14 text-uppercase semibold sp-20">18.000 users</div>
                    <ul
                      className="mt-10 color-heading f-18 medium text-adaptive"
                      style={{ listStyleType: 'none', padding: 0, marginBottom: 0 }}
                    >
                      <li>Advanced bootstrap builder mode</li>
                      <li>Import / export themes</li>
                      <li>Unlimited private projects</li>
                      <li>Auto-updated CDN</li>
                      <li>Export SASS and CSS</li>
                      <li>Reuse hundreds of free themes</li>
                    </ul>
                    <Link to="/signup" className="btn mt-35 lg action-2">Sign Up</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
)

export default Landing
