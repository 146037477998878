import { message } from 'antd'
import { patchConfig } from '../utils'

export const GET_USER = 'GET_USER'
export const GET_USER_RESOLVE = 'GET_USER_RESOLVE'
export const SHOW_OPT_IN_MODAL = 'SHOW_OPT_IN_MODAL'
export const HIDE_OPT_IN_MODAL = 'HIDE_OPT_IN_MODAL'
export const SHOW_LOGIN_MODAL = 'SHOW_LOGIN_MODAL'
export const HIDE_LOGIN_MODAL = 'HIDE_LOGIN_MODAL'

const resolveUser = payload => ({
  type: GET_USER_RESOLVE,
  payload,
})

const requestUser = () => ({
  type: GET_USER,
})

export const showOptinModal = () => ({
  type: SHOW_OPT_IN_MODAL,
})

export const hideOptinModal = () => ({
  type: HIDE_OPT_IN_MODAL,
})

export const skipOptin = () => async (dispatch) => {
  dispatch(hideOptinModal())
  const updateUser = await fetch('/api/user/me', {
    ...patchConfig,
    body: JSON.stringify({
      optin: false,
      show_optin: false,
    }),
  })
  if (updateUser.ok) {
    message.success('No problem!')
  } else {
    message.error('Something went wrong')
  }
}

export const subscribe = () => async (dispatch) => {
  dispatch(hideOptinModal())
  const updateUser = await fetch('/api/user/me', {
    ...patchConfig,
    body: JSON.stringify({
      optin: true,
      show_optin: false,
    }),
  })
  if (updateUser.ok) {
    message.success('Awesome! Talk to you later!')
  } else {
    message.error('Something went wrong')
  }
}

export const getUser = () => async (dispatch) => {
  try {
    dispatch(requestUser())
    const user = await (await fetch('/api/user/me'))
    if (user.ok) {
      const userData = await user.json()
      dispatch(resolveUser({
        data: userData,
      }))
      if (!userData.optin && userData.show_optin) {
        dispatch(showOptinModal())
      }
    } else {
      dispatch(resolveUser())
    }
  } catch (err) {
    // console.log(err)
  }
}

export const showLoginModal = () => ({
  type: SHOW_LOGIN_MODAL,
})

export const hideLoginModal = () => ({
  type: HIDE_LOGIN_MODAL,
})
