const GoogleFonts = require('../data/google-fonts.json')

const googleFontNames = GoogleFonts.map(font => font.family)

const parseGoogleFontsFromVariables = (variables) => {
  const fonts = []
  Object.keys(variables).forEach((key) => {
    if (key.indexOf('font-family') !== -1 && variables[key].indexOf('$') === -1) {
      const fontArray = variables[key].split(',').map(_ => _.trim())
      fonts.push(...fontArray.filter(font => googleFontNames.indexOf(font) !== -1))
    }
  })
  return fonts
}

const parseFontWeightsFromVariables = (variables) => {
  const defaultWeights = [300, 400, 700]
  const weights = []
  Object.keys(variables).forEach((key) => {
    if (key.indexOf('font-weight') !== -1 && Number(variables[key])) {
      if (weights.indexOf(Number(variables[key])) === -1) {
        weights.push(Number(variables[key]))
      }
    }
  })
  return weights.length > 0 ? weights : defaultWeights
}

const getGoogleFontsQueries = (fontFamilies, fontWeights) => fontFamilies.map(family => `${family}:${fontWeights.join(',')}`)

const getGoogleFontQueriesFromVariables = variables => getGoogleFontsQueries(parseGoogleFontsFromVariables(variables), parseFontWeightsFromVariables(variables))

export {
  parseGoogleFontsFromVariables,
  parseFontWeightsFromVariables,
  getGoogleFontQueriesFromVariables,
  getGoogleFontsQueries,
}
