import { applyOverwrites, setCustomScss, setFonts } from '../builder/builder.actions'
import { getGoogleFontQueriesFromVariables } from '../utils'

export const GOT_PROJECT = 'GOT_PROJECT'
export const GET_PROJECT = 'GET_PROJECT'
export const GOT_PROJECTS = 'GOT_PROJECTS'
export const GET_PROJECTS = 'GET_PROJECTS'
export const DELETE_PROJECT = 'DELETE_PROJECT'
export const FLUSH_PROJECT = 'FLUSH_PROJECT'

export const gotProject = payload => ({
  type: GOT_PROJECT,
  payload,
})

export const gotProjects = payload => ({
  type: GOT_PROJECTS,
  payload,
})

export const flushProject = () => ({
  type: FLUSH_PROJECT,
})

export const requestProject = () => ({
  type: GET_PROJECT,
})

export const getProject = id => async (dispatch) => {
  if (!id) {
    return dispatch(gotProject({
      failed: true,
    }))
  }
  try {
    dispatch(requestProject())
    const project = await fetch(`/api/projects/${id}`)
    if (project.ok) {
      const { variables, customSass, ...data } = await project.json()
      dispatch([
        gotProject({
          data,
        }),
        applyOverwrites(variables),
        setFonts(getGoogleFontQueriesFromVariables(variables)),
        setCustomScss(customSass),
      ])
    } else {
      dispatch(gotProject({
        failed: true,
      }))
    }
  } catch (err) {
    dispatch(gotProject({
      failed: true,
    }))
  }
}

export const getProjects = () => async (dispatch) => {
  try {
    const projectsResponse = await fetch('/api/projects')
    if (projectsResponse.ok) {
      const projects = await projectsResponse.json()
      dispatch(gotProjects(projects))
    } else {
      dispatch(gotProjects())
    }
  } catch (err) {
    dispatch(gotProjects())
  }
}

export const deleteProject = id => async (dispatch) => {
  if (!id) {
    return
  }
  try {
    const deleteRequest = await fetch(`/api/projects/${id}`, {
      method: 'DELETE',
    })
    if (deleteRequest.ok) {
      dispatch(getProjects())
    }
  } catch (err) {
    //
  }
}
