import Signup from './signup.component'
import Login from './loginPage.component'
import Forgot from './forgot.component'

import './scss/auth.scss'
import './scss/login.scss'

export * from './auth.actions'

export {
  Signup,
  Login,
  Forgot,
}
