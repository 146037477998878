import { combineReducers } from 'redux'

import user from './auth/auth.reducer'
import project from './project/project.reducer'
import builder from './builder/builder.reducer'

export default combineReducers({
  user,
  project,
  builder,
})
