import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import useWindowWidth from '../hooks/useWindowWidth'

const MobileNavigation = () => {
  const width = useWindowWidth()
  const [menu, setMenu] = useState(false)

  return (
      <>
        <span
          className={`open_menu bg-light radius_full ${width < 1199 ? 'd-block' : ''}`}
          onClick={() => setMenu(true)}
        >
          <i className="fas fa-bars lh-40" />
        </span>
        <div className={`navigation_mobile bg-dark type1 ${menu ? 'opened' : ''}`}>
          <span
            className="close_menu color-white"
            onClick={() => setMenu(false)}
          >
            <i className="fas fa-times" />
          </span>
          <div className="px-40 pt-60 pb-60 inner">
            <div className="logo color-white mb-30">Bootstrap Build</div>

            <Link to="/" className="f-heading f-22 link color-white mb-20">Home</Link>
            <Link to="/themes" className="f-heading f-22 link color-white mb-20">Free Themes</Link>
            <Link to="/templates" className="f-heading f-22 link color-white mb-20">Free Templates</Link>
            <Link to="/login" className="f-heading f-22 link color-white mb-20">Log In</Link>
            <Link to="/signup" className="f-heading f-22 link color-white mb-20">Sign Up</Link>

            <div className="socials mt-40">
              <a href="https://twitter.com/bootstrap_build" target="_blank" rel="noopener noreferrer" className="link color-white f-18 mr-20">
                <i className="fab fa-twitter" />
              </a>
            </div>
          </div>
        </div>
  </>
  )
}


export default MobileNavigation
