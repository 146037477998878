import React from 'react'
import { Modal } from 'antd'

const OptinModal = ({ visible, onSkip, onSubscribe }) => (
  <div>
    <Modal
      visible={visible}
      title="Email settings"
      okText="Subscribe"
      cancelText="Skip"
      onCancel={onSkip}
      onOk={onSubscribe}
    >
      <div className="h-overflow">
        <img src="/images/optin.svg" alt="email" width={140} style={{ marginRight: 20 }} className="pull-left" />
        <span>
          We'd like to send you emails with builder updates, awesome articles & dev tools.
          {' '}
          <br />
          <br />
          We don't send more than 1 email/week.
        </span>
      </div>
    </Modal>
  </div>
)

export default OptinModal
