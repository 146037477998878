import getReferencingVariables from './get-referencing-variables'
import resolveVariables from './resolve-variables'
import parseSassFile from './parse-sass-file'
import concatVariables from './concat-variables'

import {
  parseGoogleFontsFromVariables,
  parseFontWeightsFromVariables,
  getGoogleFontsQueries,
  getGoogleFontQueriesFromVariables,
} from './fonts'

export { default as categorizeVariable } from './categorize-variable'

export const download = (filename, data) => {
  const element = document.createElement('a')
  element.setAttribute('href', `data:text/text;base64,${btoa(data)}`)
  element.setAttribute('download', filename)
  document.body.appendChild(element)
  element.click()
}

export const postConfig = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
}

export const patchConfig = {
  method: 'PATCH',
  headers: {
    'Content-Type': 'application/json',
  },
}

export {
  resolveVariables,
  getReferencingVariables,
  parseSassFile,
  concatVariables,
  parseGoogleFontsFromVariables,
  parseFontWeightsFromVariables,
  getGoogleFontsQueries,
  getGoogleFontQueriesFromVariables,
}
