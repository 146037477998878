import React, { Component, Suspense } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import ClipboardJS from 'clipboard'
import helmets from './helmets'
import {
  getUser as getUserActionCreator,
  skipOptin as skipOptinActionCreator,
  subscribe as subscribeActionCreator,
  hideLoginModal as hideLoginModalActionCreator,
} from './auth'

import history from './utils/history'
import OptinModal from './auth/optinModal.component'
import ConfirmEmailModal from './auth/confirmEmailModal.component'
import LoginFormModal from './auth/loginModal.component'
import ScrollToTop from './utils/scrollToTop'
import Landing from './pages/landing'

const Routes = React.lazy(() => import('./routes'))

new ClipboardJS('.copy-to-clipboard')

class App extends Component {
  render() {
    const {
      user,
      skipOptin,
      subscribe,
      hideLoginModal,
    } = this.props
    return <>
      <Router history={history}>
        {helmets}
      </Router>
      <Router history={history}>
        <Switch>
          <ScrollToTop>
            <Route path="/" component={Landing} exact />
            <Suspense fallback={<div />}>
              <Route render={props => <Routes {...props} />} />
            </Suspense>
          </ScrollToTop>
        </Switch>
      </Router>
      <Router history={history}>
        <div>
          <OptinModal
            visible={!user.loading && user.showOptinModal && user.data.email_verified}
            onSubscribe={subscribe}
            onSkip={skipOptin}
          />
          <ConfirmEmailModal
            visible={!user.loading && user.data && !user.data.email_verified}
            email={user.data && user.data.email}
          />
          <LoginFormModal
            onCancel={hideLoginModal}
            visible={user.showLoginModal}
          />
        </div>
      </Router>
    </>
  }
}

const mapDispatchToProps = {
  getUser: getUserActionCreator,
  skipOptin: skipOptinActionCreator,
  subscribe: subscribeActionCreator,
  hideLoginModal: hideLoginModalActionCreator,
}

const mapStateToProps = ({ user, paywall }) => ({
  user,
  paywall,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App)
